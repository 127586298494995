import store from '@state/store'
import refreshUser from '../utils/refreshUser'
export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => lazyLoadView(import('@views/Dashboard')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: false,
    },
    component: () => lazyLoadView(import('@views/home')),
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/login')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        const hasFetchValidation = store.getters['auth/hasFetchValidation']
        const hasLoadedUserFirstTime =
          store.getters['user/hasLoadedUserFirstTime']

        if (hasFetchValidation || hasLoadedUserFirstTime) {
          const currentUser = store.getters['user/currentUser']

          if (!currentUser) {
            return next()
          }
          return next({ name: 'dashboard' })
        }
        const user = await refreshUser
        return user ? next({ name: 'dashboard' }) : next()
      },
    },
  },
  {
    path: '/clients',
    component: () => lazyLoadView(import('@views/ClientRoot')),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'clients',
        component: () => lazyLoadView(import('@views/Clients')),
      },
      {
        path: 'new-client',
        name: 'new-client',
        component: () => lazyLoadView(import('@views/NewClient')),
      },
      {
        path: 'edit',
        name: 'edit-client',
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            const { name } = routeFrom

            if (name === 'clients') {
              return next()
            }
            return next({ name: 'clients' })
          },
        },
        component: () => lazyLoadView(import('@views/EditClient')),
        props: true,
      },
    ],
  },
  {
    path: '/shareholders',
    component: () => lazyLoadView(import('@views/ShareholderRoot')),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'shareholders',
        component: () => lazyLoadView(import('@views/Shareholders')),
      },
      {
        path: 'new-shareholder',
        name: 'new-shareholder',
        component: () => lazyLoadView(import('@views/NewShareholder')),
      },
      {
        path: 'edit',
        name: 'edit-shareholder',
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            const { name } = routeFrom

            if (name === 'shareholders') {
              return next()
            }
            return next({ name: 'shareholders' })
          },
        },
        component: () => lazyLoadView(import('@views/EditShareholder')),
        props: true,
      },
    ],
  },
  {
    path: '/risk',
    name: 'risk',
    component: () => lazyLoadView(import('@views/Risk')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/accounting',
    component: () => lazyLoadView(import('@views/AccountingRoot')),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'accounting',
        component: () => lazyLoadView(import('@views/Accounting')),
      },
      {
        path: 'edit',
        name: 'edit-accounting',
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            const { name } = routeFrom
            if (name === 'accounting') {
              return next()
            }
            return next({ name: 'accounting' })
          },
        },
        component: () => lazyLoadView(import('@views/EditAccounting')),
        props: true,
      },
    ],
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => lazyLoadView(import('@views/Documents')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => lazyLoadView(import('@views/Users')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/reminders',
    name: 'reminders',
    component: () => lazyLoadView(import('@views/Reminders')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => lazyLoadView(import('@views/profile')),
    meta: {
      authRequired: true,
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => lazyLoadView(import('@views/profile')),
    meta: {
      authRequired: true,
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: 'home',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
