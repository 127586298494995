import * as constants from './constants'

const mutations = {
  [constants.SAVE_CURRENT_USER]: (state, payload) => {
    state.current = { ...payload, claims: {} }
    state.hasLoadedUserFirstTime = true
  },
  [constants.DELETE_CURRENT_USER]: (state) => {
    state.current = null
    state.hasLoadedUserFirstTime = true
  },
  [constants.FETCHING_CUSTOM_CLAIMS](state) {
    state.fetching = true
  },
  [constants.FETCH_CUSTOM_CLAIMS_SUCCESS](state, claims) {
    state.current.claims = claims
    state.fetching = false
  },
  [constants.FETCH_CUSTOM_CLAIMS_ERROR](state, err) {
    state.error = err
    state.fetching = false
  },
  [constants.FETCHING_USERS](state) {
    state.fetching = true
  },
  [constants.FETCH_USERS_SUCCESS](state, users) {
    state.fetching = false
    state.users = users
  },
  [constants.FETCH_USERS_ERROR](state, err) {
    state.fetching = false
    state.error = err
  },
  [constants.CREATING_USER](state) {
    state.fetching = true
  },
  [constants.CREATE_USER_SUCCESS](state, users) {
    state.fetching = false
    state.users = users
  },
  [constants.CREATE_USER_ERROR](state, err) {
    state.fetching = false
    state.error = err
  },
  [constants.UPDATING_USER](state) {
    state.fetching = true
  },
  [constants.UPDATE_USER_SUCCESS](state, users) {
    state.fetching = false
    state.users = users
  },
  [constants.UPDATE_USER_ERROR](state, err) {
    state.fetching = false
    state.error = err
  },
  [constants.DELETING_USER](state) {
    state.fetching = true
  },
  [constants.DELETE_USER_SUCCESS](state, users) {
    state.fetching = false
    state.users = users
  },
  [constants.DELETE_USER_ERROR](state, err) {
    state.fetching = false
    state.error = err
  },
  [constants.EDIT_CURRENT_USER_FETCHING](state) {
    state.fetching = true
  },
  [constants.EDIT_CURRENT_USER_SUCCESS](state, user) {
    state.fetching = false
    state.current = {
      ...user,
      claims: {
        ...state.current.claims,
      },
    }
  },
  [constants.EDIT_CURRENT_USER_ERROR](state, err) {
    state.fetching = false
    state.error = err
  },
  [constants.REAUTHENTICATE_CREDENTIAL_FETCHING](state) {
    state.fetching = true
  },
  [constants.REAUTHENTICATE_CREDENTIAL_SUCCESS](state, user) {
    state.fetching = false
    state.current = {
      ...user,
      claims: {
        ...state.current.claims,
      },
    }
  },
  [constants.REAUTHENTICATE_CREDENTIAL_ERROR](state, err) {
    state.fetching = false
    state.error = err
  },
  setAvatars(state, avatars) {
    state.avatars = avatars
  },
}

export default mutations
