<script>
import { mapActions, mapGetters } from 'vuex'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
export default {
  name: 'Layout',
  data() {
    return {
      errorMessage: null,
      eventBusError: null,
      eventBusSuccess: null,
      drawer: true,
      modal: {
        open: false,
        action: null,
        activeCancel: true,
        title: '',
        message: '',
        confirmAction: null,
      },
      snackbar: {
        open: false,
        color: 'error',
        message: null,
      },
      items: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          route: 'dashboard',
        },
        {
          title: 'Clients',
          icon: 'mdi-account-group',
          route: 'clients',
        },
        {
          title: 'Shareholders',
          icon: 'mdi-account-supervisor',
          route: 'shareholders',
        },
        {
          title: 'Users',
          icon: 'mdi-shield-account',
          route: 'users',
        },
        {
          title: 'Documents',
          icon: 'mdi-file',
          route: 'documents',
        },
        {
          title: 'Accounting',
          icon: 'mdi-credit-card',
          route: 'accounting',
        },
        {
          title: 'Risk',
          icon: 'mdi-security',
          route: 'risk',
        },
        {
          title: 'Reminders',
          icon: 'mdi-alarm',
          route: 'reminders',
        },
      ],
      userMenu: [
        {
          title: 'My Profile',
          icon: 'mdi-account',
          route: 'profile',
        },
        {
          title: 'Logout',
          icon: 'mdi-exit-to-app',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      authError: 'auth/error',
      isAdminUser: 'user/isAdminUser',
      userError: 'user/error',
      currentUser: 'user/currentUser',
    }),

    name() {
      return this.currentUser && this.currentUser.displayName
        ? this.currentUser.displayName
        : ''
    },
    itemsFiltered() {
      return this.items.filter(
        (item) => !(item.title === 'Users' && !this.isAdminUser)
      )
    },
  },
  watch: {
    authError(newValue) {
      if (newValue) {
        this.snackbar.open = true
        this.snackbar.color = 'error'
        this.snackbar.message = newValue
      }
    },
    userError(newValue) {
      if (newValue) {
        this.snackbar.open = true
        this.snackbar.color = 'red'
        this.snackbar.message = newValue.message
      }
    },
    documentError(newValue) {
      if (newValue) {
        this.snackbar.open = true
        this.snackbar.color = 'red'
        this.snackbar.message = newValue.message
      }
    },
    eventBusError: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          const { status } = newValue
          this.snackbar.open = true
          this.snackbar.color = status === 409 ? 'yellow darken-4' : 'red'
          this.snackbar.message = newValue.message
        }
      },
    },
    eventBusSuccess: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.snackbar.open = true
          this.snackbar.color = 'green'
          this.snackbar.message = newValue.message
        }
      },
    },
  },
  mounted() {
    EventBus.$on(EventBusEvents.SNACKBAR_ERROR, this.snackbarError)
    EventBus.$on(EventBusEvents.SNACKBAR_SUCCESS, this.snackbarSuccess)
    EventBus.$on(EventBusEvents.OPEN_MODAL, this.openModal, this)
  },
  beforeDestroy() {
    EventBus.$off(EventBusEvents.SNACKBAR_ERROR, this.snackbarError)
    EventBus.$off(EventBusEvents.SNACKBAR_SUCCESS, this.snackbarSuccess)
    EventBus.$off(EventBusEvents.OPEN_MODAL, this.openModal, this)
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    snackbarError(err) {
      this.eventBusError = err
    },
    snackbarSuccess(msg) {
      this.eventBusSuccess = msg
    },
    openModal({
      title = 'Confirm',
      message = "Are you sure to go to the next step? You won't be able to go back to this step later.",
      confirmAction,
      activeCancel = true,
    }) {
      this.modal.open = true
      this.modal.confirmAction = confirmAction
      this.modal.title = title
      this.modal.message = message
      this.modal.activeCancel = activeCancel
    },
    modalConfirm() {
      this.modal.open = false
      return typeof this.modal.confirmAction === 'function'
        ? this.modal.confirmAction()
        : EventBus.$emit(this.modal.confirmAction, {}, this)
    },
    modalCancel() {
      this.modal.open = false
    },
  },
}
</script>

<template>
  <div>
    <v-dialog v-model="modal.open" persistent max-width="290">
      <v-card class="elevation-0" outlined>
        <v-card-title class="text-h5">{{ modal.title }}</v-card-title>
        <v-card-text>{{ modal.message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="modal.activeCancel"
            color="red darken-1"
            text
            @click="modalCancel"
            >Cancel</v-btn
          >
          <v-btn id="btnConfirm" color="teal" text @click="modalConfirm"
            >Continue</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="drawer" :temporary="false" clipped app width="220">
      <v-list nav dense>
        <v-list-item
          v-for="(item, index) in itemsFiltered"
          :key="`drawer-item-${index}`"
          :to="item.route ? { name: item.route } : undefined"
          link
          color="red darken-4"
          :ripple="{ class: 'red--text' }"
          @click="item.title === 'Logout' ? logOut() : null"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="#fff" clipped-left app class="elevation-1">
      <v-app-bar-nav-icon class="mr-5 hidden-lg-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-avatar tile class="mr-5">
        <v-img src="@assets/mg_logo_red_small.png"></v-img>
      </v-avatar>
      <v-toolbar-title class="text-h5 text-uppercase">
        <span class="red-text">ECM</span>
        <span class="font-weight-light">DP</span>
      </v-toolbar-title>
      <v-spacer flex-grow></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card width="200px">
          <v-card-title>Hi {{ name }}!</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0">
            <v-list nav dense>
              <v-list-item
                v-for="(item, index) in userMenu"
                :key="`drawer-item-${index}`"
                :to="item.route ? { name: item.route } : undefined"
                link
                color="red darken-4"
                :ripple="{ class: 'red--text' }"
                @click="item.title === 'Logout' ? logOut() : null"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main app>
      <v-container fluid>
        <v-fade-transition group>
          <div key="main-element">
        <slot />
      </div>
        </v-fade-transition>
      </v-container>
    </v-main>

    <v-footer dark app inset class="small">
      <div>ECMDP v0.8.9</div>
      <div class="flex-grow-1"></div>
      <div>
        Built with
        <v-icon small color="red">mdi-heart</v-icon>by
        <a
          target="_blank"
          href="https://www.kiquix.com"
          class="red--text darken-4"
          >Kiquix</a
        >
        for
        <a
          target="_blank"
          href="https://www.mediterragroup.com/"
          class="red--text darken-4"
          >Mediterra Group</a
        >
        &copy; {{ new Date().getFullYear() }}
      </div>
    </v-footer>
    <v-snackbar v-model="snackbar.open" top :color="snackbar.color">
      <span>{{ snackbar.message }}</span>
      <v-btn dark text @click="snackbar.open = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<style lang="scss">
@import '@design';
.small {
  font-size: 0.675rem;
}
</style>
