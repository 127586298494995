import firebaseApp from '../../../firebase/app'
import firebase from 'firebase/app'

import 'firebase/auth'

import {
  authorizationHeader,
  httpClient,
  handleErrors,
} from '@utils/httpClient'
import * as constants from './constants'

const actions = {
  saveCurrentUser: ({ commit }, payload) => {
    const { displayName, email, phoneNumber } = payload

    const safeUser = {
      displayName,
      email,
      phoneNumber,
    }
    commit(constants.SAVE_CURRENT_USER, safeUser)
  },
  deleteCurrentUser: ({ commit }, payload) => {
    commit(constants.DELETE_CURRENT_USER, payload)
  },
  async getCustomClaims({ commit }) {
    commit(constants.FETCHING_CUSTOM_CLAIMS)
    try {
      const idTokenResult = await firebaseApp
        .auth()
        .currentUser.getIdTokenResult()
      const { claims } = idTokenResult

      const safeClaims = {
        admin: claims && claims.admin || false
      }
      commit(constants.FETCH_CUSTOM_CLAIMS_SUCCESS, safeClaims)
    } catch (err) {
      commit(constants.FETCH_CUSTOM_CLAIMS_ERROR, err)
    }
  },
  async getAllUsers({ commit }, payload) {
    const { showCurrent } = payload || {}
    commit(constants.FETCHING_USERS)
    try {
      const idToken = await firebaseApp.auth().currentUser.getIdToken(true)
      const { data } = await httpClient.get('/user/all', {
        params: { showCurrent: showCurrent || false },
        ...authorizationHeader(idToken),
      })

      const { users } = data || {}
      commit(constants.FETCH_USERS_SUCCESS, users)
    } catch (err) {
      const error = handleErrors(err)
      commit(constants.FETCH_USERS_ERROR, error)
    }
  },
  async createUser({ commit }, payload) {
    commit(constants.CREATING_USER)

    try {
      const idToken = await firebaseApp.auth().currentUser.getIdToken(true)
      const { data } = await httpClient.post('/user', {
        body: payload,
        ...authorizationHeader(idToken),
      })
      const { users } = data || {}
      commit(constants.CREATE_USER_SUCCESS, users)
    } catch (err) {
      const error = handleErrors(err)
      commit(constants.CREATE_USER_ERROR, error)
    }
  },
  async updateUser({ commit }, payload) {
    commit(constants.UPDATING_USER)

    try {
      const idToken = await firebaseApp.auth().currentUser.getIdToken(true)
      const { data } = await httpClient.put('/user', {
        body: payload,
        ...authorizationHeader(idToken),
      })
      const { users } = data || {}
      commit(constants.UPDATE_USER_SUCCESS, users)
    } catch (err) {
      const error = handleErrors(err)
      commit(constants.UPDATE_USER_ERROR, error)
    }
  },
  async deleteUser({ commit }, payload) {
    commit(constants.DELETING_USER)
    try {
      const idToken = await firebaseApp.auth().currentUser.getIdToken(true)
      const { data } = await httpClient.delete('/user', {
        body: payload,
        ...authorizationHeader(idToken),
      })
      const { users } = data || {}
      commit(constants.DELETE_USER_SUCCESS, users)
    } catch (err) {
      const error = handleErrors(err)
      commit(constants.DELETE_USER_ERROR, error)
    }
  },
  async editCurrentUser({ commit }, payload) {
    commit(constants.EDIT_CURRENT_USER_FETCHING)

    try {
      const idToken = await firebaseApp.auth().currentUser.getIdToken(true)
      const { data: user } = await httpClient.put('/user/current', {
        body: payload,
        ...authorizationHeader(idToken),
      })
      commit(constants.EDIT_CURRENT_USER_SUCCESS, user)
      return user
    } catch (err) {
      const error = handleErrors(err)
      commit(constants.EDIT_CURRENT_USER_ERROR, error)
      throw err
    }
  },
  async reauthenticateWithCredentials({ commit }, { email, password }) {
    commit(constants.REAUTHENTICATE_CREDENTIAL_FETCHING)

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      )

      const currentUser = firebaseApp.auth().currentUser
      const { user } = await currentUser.reauthenticateWithCredential(
        credential
      )

      const { displayName, email: nEmail, phoneNumber } = user
      const safeUser = { displayName, email: nEmail, phoneNumber }

      commit(constants.REAUTHENTICATE_CREDENTIAL_SUCCESS, safeUser)
      return user
    } catch (err) {
      const error = handleErrors(err)
      commit(constants.REAUTHENTICATE_CREDENTIAL_ERROR, error)
      throw err
    }
  },
  // fetchAvatars ({ commit, state }) {
  //     if (Object.keys(state.avatars).length) {
  //         return state.avatars
  //     }

  //     let avatars = {}
  //     let files = require.context('~/static/avatars', true, /\.png$/i)
  //     files.keys().map((key) => {
  //         let id = key.split('/').pop().split('.')[0].substring(7).toUpperCase()
  //         avatars[id] = {
  //             path: key.split('/').pop(),
  //             id: id
  //         }
  //     })

  //     commit('setAvatars', avatars)
  // }
}

export default actions
