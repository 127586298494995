export default {
  config: {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  }
}