import Vue from 'vue'

class EventBus extends Vue {
  $on(event, ...args) {
    if (process.env.NODE_ENV === 'development') {
      const [, vm] = args
      console.group(`EventBus $on, event: ${event}`)
      if(vm) console.log(`Component: ${vm.$options.name}`)
      console.groupEnd(`EventBus $on, event: ${event}`)
    }
    return super.$on(event, ...args)
  }

  $off(event, ...args) {
    if (process.env.NODE_ENV === 'development') {
      const [, vm] = args
      console.group(`EventBus $off, event: ${event}`)
      if(vm) console.log(`Component: ${vm.$options.name}`)
      console.groupEnd(`EventBus $off, event: ${event}`)
    }
    return super.$off(event, ...args)
  }

  $emit(event, ...args) {
    if (process.env.NODE_ENV === 'development') {
      const [params, vm] = args

      console.group(`EventBus $emit triggered, event: ${event}`)
      if(vm) console.log(`Component: ${vm.$options.name}`)
      console.dir(params)
      console.groupEnd(`EventBus Emit triggered, event: ${event}`)
    }
    return super.$emit(event, ...args)
  }
}
export default new EventBus()
