export const EventBusEvents = {
  ADD_NEW_CLIENT_INDIVIDUAL_INFO: 'ADD_NEW_CLIENT_INDIVIDUAL_INFO',
  ADD_NEW_CLIENT_INDIVIDUAL_DOCUMENTS: 'ADD_NEW_CLIENT_INDIVIDUAL_DOCUMENTS',
  ADD_NEW_CLIENT_SHAREHOLDER: 'ADD_NEW_CLIENT_SHAREHOLDER',
  ADD_NEW_CORPORATE_CLIENT: 'ADD_NEW_CORPORATE_CLIENT',
  OPEN_MODAL: 'OPEN_MODAL',
  RESET_CREATE_SHAREHOLDER_ACCORDION: 'RESET_CREATE_SHAREHOLDER_ACCORDION',
  SNACKBAR_ERROR: 'SNACKBAR_ERROR',
  SNACKBAR_SUCCESS: 'SNACKBAR_SUCCESS',
  RESET_NEW_CLIENT: 'RESET_NEW_CLIENT',
  DRAFT_NEW_CLIENT: 'DRAFT_NEW_CLIENT',
  ON_SAVE_CLICK_NEW_SHAREHOLDER: 'ON_SAVE_CLICK_NEW_SHAREHOLDER',
  ON_SAVE_CLICK_NEW_CLIENT: 'ON_SAVE_CLICK_NEW_CLIENT',
  ON_STEP_CHANGE: 'ON_STEP_CHANGE',
  ON_SAVE_CLICKED: 'ON_SAVE_CLICKED',
  ON_SHAREHOLDER_SELECTED: 'ON_SHAREHOLDER_SELECTED',
  ON_CREATE_CLIENT_COMPLETE: 'ON_CREATE_CLIENT_COMPLETE',
  ON_CORPORATE_CLIENT_CLICK: 'ON_CORPORATE_CLIENT_CLICK',
  ON_CORPORATE_CLIENT_SAVE_SUCCESS: 'ON_CORPORATE_CLIENT_SAVE_SUCCESS',
  ON_CORPORATE_VALIDATE_CLIENT_INFO: 'ON_CORPORATE_VALIDATE_CLIENT_INFO',
  ON_CORPORATE_VALIDATE_CLIENT_DOCUMENTS:
    'ON_CORPORATE_VALIDATE_CLIENT_DOCUMENTS',
}

export const documentTypes = {
  passport: 'Passport/Id card',
  reference: 'Bank/Professional Reference',
  residenceProof: 'Proof of residence',
  complianceCheck: 'Compliance Check',
  personalQuestionnaire: 'Personal Questionnaire',
  sourceWealth: 'Source of wealth',
  sourceFunds: 'Source of funds',
  vatCertificate: 'Vat certificate',
  incorporationCertificate: 'Incorporation Certificate',
  'm&a': 'M&A',
  engagementLetter: 'Letter of engagement',
  siaDirector: 'SIA Director',
  siaCompanySecretary: 'SIA Company Secretary',
  authorizationMbr: 'Authorization with MBR',
  other: 'Other',
}

export const AccountingDocuments = {
  incomeTaxReturn: 'Income Tax Return',
  vatReturn: 'VAT Return',
  fsStatement: 'FS Statement'
}

export const ClientTypes = {
  INDIVIDUAL: 'individual',
  CORPORATE: 'corporate',
}

export const ClientTypesFull = {
  INDIVIDUAL: 'individual',
  CORPORATE: 'corporate',
  SHAREHOLDER: 'shareholder',
}

export const documentsIndividual = [
  {
    label: 'Passport/Id card',
    type: 'passport',
  },
  {
    label: 'Bank/Professional Reference',
    type: 'reference',
  },
  {
    label: 'Proof of residence',
    type: 'residenceProof',
  },
  {
    label: 'Compliance Check',
    type: 'complianceCheck',
  },
  {
    label: 'Personal Questionnaire',
    type: 'personalQuestionnaire',
  },
  {
    label: 'Source of wealth',
    type: 'sourceWealth',
  },
  {
    label: 'Source of funds',
    type: 'sourceFunds',
  },
  {
    label: 'Vat certificate',
    type: 'vatCertificate',
  },
  {
    label: 'Other',
    type: 'other',
  },
]

export const AllowedFileExtensions = ['jpg', 'jpeg', 'png', 'pdf']

export const documentsCorporate = [
  {
    label: 'Passport/Id card',
    type: 'passport',
  },
  {
    label: 'Bank/Professional Reference',
    type: 'reference',
  },
  {
    label: 'Proof of residence',
    type: 'residenceProof',
  },
  {
    label: 'Compliance Check',
    type: 'complianceCheck',
  },
  {
    label: 'Personal Questionnaire',
    type: 'personalQuestionnaire',
  },
  {
    label: 'Source of wealth',
    type: 'sourceWealth',
  },
  {
    label: 'Source of funds',
    type: 'sourceFunds',
  },
  {
    label: 'Vat certificate',
    type: 'vatCertificate',
  },
  {
    label: 'Incorporation Certificate',
    type: 'incorporationCertificate',
  },
  {
    label: 'M&A',
    type: 'm&a',
  },
  {
    label: 'Letter of engagement',
    type: 'engagementLetter',
  },
  {
    label: 'SIA Director',
    type: 'siaDirector',
  },
  {
    label: 'SIA Company Secretary',
    type: 'siaCompanySecretary',
  },
  {
    label: 'Authorization with MBR',
    type: 'authorizationMbr',
  },
  {
    label: 'Other',
    type: 'other',
  },
]

export const IndividualClientFields = [
  'passport',
  'reference',
  'residenceProof',
  'complianceCheck',
  'personalQuestionnaire',
  'sourceWealth',
  'sourceFunds',
  'vatCertificate',
  'other',
]

export const MandatoryIndividualDocuments = [
  'passport',
  'reference',
  'residenceProof',
  'complianceCheck',
  'personalQuestionnaire',
  'sourceWealth',
  'sourceFunds',
]

export const MandatoryIndividualShareholderFields = [
  'passport',
  'reference',
  'residenceProof',
  'complianceCheck',
  'personalQuestionnaire',
  'sourceWealth',
  'sourceFunds',
]

export const MandatoryCorporateShareholderFields = [
  'passport',
  'reference',
  'residenceProof',
  'complianceCheck',
  'personalQuestionnaire',
  'incorporationCertificate',
  'm&a',
]

export const CorporateClientFields = [
  'vatCertificate',
  'incorporationCertificate',
  'm&a',
  'engagementLetter',
  'siaDirector',
  'siaCompanySecretary',
  'authorizationMbr',
  'other',
]

export const IndividualShareholderFields = [
  'passport',
  'reference',
  'residenceProof',
  'complianceCheck',
  'personalQuestionnaire',
  'sourceWealth',
  'sourceFunds',
  'vatCertificate',
  'other',
]

export const CorporateShareholderFields = [
  'passport',
  'reference',
  'residenceProof',
  'complianceCheck',
  'personalQuestionnaire',
  'sourceWealth',
  'sourceFunds',
  'vatCertificate',
  'incorporationCertificate',
  'm&a',
  'engagementLetter',
  'siaDirector',
  'siaCompanySecretary',
  'authorizationMbr',
  'other',
]

export const vatReturnPeriodType = {
  MONTHLY: 'monthly',
  QUARTERLY:'quarterly',
  YEARLY: 'yearly'
}
