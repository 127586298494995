import * as constants from './constants';
import firebase from '../../../firebase/app'
import router from '../../../router/index';

const actions = {
  async logInEmailAndPassword ({ commit }, authData) {
    commit(constants.LOGIN_EMAIL_FETCHING);

    const { email, password } = authData;

    try {
      const user = await firebase.auth().signInWithEmailAndPassword(email, password)
      commit(constants.LOGIN_EMAIL_FETCH_SUCCESS, user)
      router.push({ name: 'dashboard' })
    } catch (err) {
      commit(constants.LOGIN_EMAIL_FETCH_ERROR, err)
    }
  },
  async setResetPasswordEmail({ commit }, email) {
    commit(constants.RESET_PASSWORD_REQUEST_FETCHING)
    try {
      await firebase.auth().sendPasswordResetEmail(email)
      commit(constants.RESET_PASSWORD_REQUEST_SUCCESS)
    } catch (err) {
      commit(constants.RESET_PASSWORD_REQUEST_ERROR, err)
    }
  },
  async logOut ({ commit }) {
    commit(constants.LOGOUT_FETCHING)
    try {
      await firebase.auth().signOut()
      commit(constants.LOGOUT_SUCCESS)
      router.push({ name: 'login' })
    } catch (err) {
      commit(constants.LOGOUT_ERROR)
    }
  }
};

export default actions;
