export const LOGIN_FETCHING = 'LOGIN_FETCHING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_FETCHING = 'LOGOUT_FETCHING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGIN_EMAIL_FETCHING = 'LOGIN_EMAIL_FETCHING'
export const LOGIN_EMAIL_FETCH_SUCCESS = 'LOGIN_EMAIL_FETCH_SUCCESS'
export const LOGIN_EMAIL_FETCH_ERROR = 'LOGIN_EMAIL_FETCH_ERROR'
export const RESET_PASSWORD_REQUEST_FETCHING = 'RESET_PASSWORD_REQUEST_FETCHING'
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS'
export const RESET_PASSWORD_REQUEST_ERROR = 'RESET_PASSWORD_REQUEST_ERROR'