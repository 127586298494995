const getters = {
  users: state => state.users,
  userLoading: state => state.fetching,
  currentUser: state => state.current,
  hasLoadedUserFirstTime: state => state.hasLoadedUserFirstTime,
  isAdminUser: state => state && state.current && state.current.claims && state.current.claims.admin,
  token: state => state.current.token,
  error: state => state.error,
};

export default getters;
