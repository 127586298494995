import Vue from 'vue'
import App from './app'
import firebase from './firebase/app'
import router from '@router'
import store from '@state/store'
import vuetify from '@plugins/vuetify'
import JsonExcel from "vue-json-excel";
import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'

window.toastr = require('toastr')

Vue.use(VueToastr2)
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = process.env.NODE_ENV === 'production'

if (process.env.VUE_APP_TEST === 'e2e') {
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    const userJson = user.toJSON()
    store.dispatch('user/saveCurrentUser', userJson)
    store.dispatch('user/getCustomClaims')
    return
  }
  store.dispatch('user/deleteCurrentUser')
})

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')


// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  window.__app__ = app
}
