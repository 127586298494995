import * as constants from './constants';

const mutations = {
  [constants.LOGIN_EMAIL_FETCHING](state) {
    state.fetching = true;
  },
  [constants.LOGIN_EMAIL_FETCH_SUCCESS](state, userData) {
    state.fetching = false;
    state.hasFetchValidation = true;
    state.user = { ...userData };
  },
  [constants.LOGIN_EMAIL_FETCH_ERROR](state, error) {
    state.fetching = false;
    state.error = error;
    state.hasFetchValidation = true;
  },
  [constants.LOGOUT_FETCHING](state) {
    state.fetching = true;
  },
  [constants.LOGOUT_SUCCESS](state) {
    state.fetching = false;
  },
  [constants.LOGOUT_ERROR](state, error) {
    state.fetching = false;
    state.error = error;
  },
  [constants.RESET_PASSWORD_REQUEST_FETCHING](state) {
    state.fetching = true;
  },
  [constants.RESET_PASSWORD_REQUEST_SUCCESS](state) {
    state.fetching = false;
  },
  [constants.RESET_PASSWORD_REQUEST_ERROR](state, err) {
    state.fetching = false;
    state.error = err;
  }
};

export default mutations;
