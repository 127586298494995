export const FETCHING_USERS = 'FETCHING_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const CREATING_USER = 'CREATING_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const EDIT_CURRENT_USER_FETCHING = 'EDIT_CURRENT_USER_FETCHING';
export const EDIT_CURRENT_USER_SUCCESS = 'EDIT_CURRENT_USER_SUCCESS';
export const EDIT_CURRENT_USER_ERROR = 'EDIT_CURRENT_USER_ERROR';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETING_USER = 'DELETING_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const SAVE_CURRENT_USER = 'SAVE_CURRENT_USER';
export const DELETE_CURRENT_USER = 'DELETE_CURRENT_USER';
export const FETCHING_CUSTOM_CLAIMS = 'FETCHING_CUSTOM_CLAIMS'
export const FETCH_CUSTOM_CLAIMS_SUCCESS = 'FETCH_CUSTOM_CLAIMS_SUCCESS'
export const FETCH_CUSTOM_CLAIMS_ERROR = 'FETCH_CUSTOM_CLAIMS_ERROR'
export const REAUTHENTICATE_CREDENTIAL_FETCHING = 'REAUTHENTICATE_CREDENTIAL_FETCHING'
export const REAUTHENTICATE_CREDENTIAL_SUCCESS = 'REAUTHENTICATE_CREDENTIAL_SUCCESS'
export const REAUTHENTICATE_CREDENTIAL_ERROR = 'REAUTHENTICATE_CREDENTIAL_ERROR'
