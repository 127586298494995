import axios from 'axios'

const baseUrl = process.env.VUE_APP_API_BASE_URL

export const httpClient = {
  get: (url, { params, headers, ...rest }) =>
    axios({
      method: 'get',
      url: `${baseUrl}${url}`,
      params,
      headers,
      ...rest,
    }),
  post: (url, { body: data, headers }) =>
    axios({
      method: 'post',
      url: `${baseUrl}${url}`,
      data,
      headers,
    }),
  put: (url, { body: data, headers }) =>
    axios({
      method: 'put',
      url: `${baseUrl}${url}`,
      data,
      headers,
    }),
  delete: (url, { body: data, headers }) =>
    axios({
      method: 'delete',
      url: `${baseUrl}${url}`,
      data,
      headers,
    }),
}

export const authorizationHeader = (idToken) => ({
  headers: {
    Authorization: `Bearer ${idToken}`,
  },
})

export const authMultipartHeaderWithParams = (idToken, params) => ({
  headers: {
    Authorization: `Bearer ${idToken}`,
    'Content-Type': 'multipart/form-data',
    ...params,
  },
})

export const handleErrors = (error) => {
  const { message, response } = error

  if (!message && !response) {
    return {
      message: 'Generic Error',
    }
  }

  if (!response) {
    return { message }
  }

  const {
    data: { message: resMessage },
    status,
  } = response || {}

  if (status === 500) {
    return {
      message: `
        There has been an issue, please try again.
        If problem persists, contact your administrator`
        .replace(/\n\s+/g, ' ')
        .trim(),
    }
  }
  return { status, message: resMessage }
}
