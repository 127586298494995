import firebase from 'firebase/app'
import credentials from './credentials'
import 'firebase/auth';

const firebaseApp = firebase.apps
  && firebase.apps.length > 0 ?
    firebase.apps[0] :
    firebase.initializeApp(credentials.config)

export default firebaseApp
