import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth/auth'
import user from './modules/user/user'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)


const plugins = () => {
  if (process.env.NODE_ENV === 'development') {
    return [
      createLogger()
    ]
  }
  return []
}

const store = new Vuex.Store({
  modules: {
    auth,
    user
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  plugins: plugins(),
  strict: process.env.NODE_ENV !== 'production',
})


export default store
