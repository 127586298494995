const state = {
  fetching: false,
  current: null,
  users: [],
  hasLoadedUserFirstTime: false,
  error: null,
  avatars: {}
};

export default state;
